//Nav.js

import { useState, useEffect } from 'react';
import logo from './images/logo/LAB-logo.png';
import hamburgerMenu from './images/hamburgerMenu.png';
import hamburgerMenuX from './images/hamburgerMenuX.png';
import shoppingCart from './images/shoppingCart.png';

const Nav = ({ token, setToken, user, setUser, showCart, setShowCart, isPlan, showPlan, showMenu, setShowMenu, setShowLogin, showConsulting }) => {

    // Hook
    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });
        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();


            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount

        return windowSize;
    }

    const size = useWindowSize();

    useEffect(() => {
        if (size.width >= 800) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
        }
    }, [size, setShowMenu])

   
    const handleClickLogout = (e) => {
        e.preventDefault();
        setUser([]);
        setToken('');
        setShowLogin(false);
        //setShowMenu(false);
    }

    const handleShowMenu = () => {
        setShowMenu(true);
    }
    const handleHideMenu = () => {
        setShowMenu(false);
    }

    const toggleShoppingCart = () => {
        setShowCart(!showCart);
    }



    return (
        <nav>
            <div className="navigation wrapper">

                <div className="garageLabIcon">
                    <a href="./">
                        <img src={logo} alt="Garage Lab Logo" />
                    </a>

                </div>

                <div className="navMenuAll">

                    {
                        !showMenu ? <img onClick={handleShowMenu} className="hamburgerMenuIcon" src={hamburgerMenu} alt="Open Menu" /> : null
                    }
                    {
                        showMenu ? <img onClick={handleHideMenu} className="hamburgerMenuIcon" src={hamburgerMenuX} alt="Close Menu" /> : null
                    }
                    {
                        showMenu ? <ul>

                            <li> <a href="#ys">Ys Books</a></li>
                            {/* <li> <a href="#workshops">Workshops and Lessons</a></li> */}

                            {
                                //user?
                                token && user ?
                                    <li><a href="#lessons">My Projects</a></li>
                                    : null
                            }

                            <li><a href="#openProjects">STEAM Projects</a></li>

                            {/* {
                                showPlan ?
                                    <li><a href="#plans">Plans</a></li>
                                    : null
                            } */}

                            {/* {showConsulting
                                ? <li><a href="#consulting">Consulting</a></li>
                                : null} */}
                            <li><a href="#contact">Contact</a></li>

                            {showCart ? <li><button onClick={toggleShoppingCart} className="logoutButton cartActive"><img alt="shopping cart" src={shoppingCart} /> Cart</button></li>
                                : <li><button onClick={toggleShoppingCart} className="logoutButton"><img alt="shopping cart" src={shoppingCart} /> Cart</button></li>
                            }

                            {
                                //!user 
                                !user?
                                    <li><a href="#login" onClick={() => { setShowLogin(true) }}>Login / New User</a></li>
                                    : <li><button className="logoutButton" onClick={handleClickLogout}>Logout {user.name.split(' ')[0]} </button></li>
                            }

                        </ul> : null
                    }
                </div>
            </div>
        </nav>

    )
}

export default Nav;