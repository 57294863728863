//Workshop.js
import './Ys.css';
import { useState, useEffect } from 'react';

import allAboutYs from './images/covers/Cover-AllAboutYs.png'
import sock from './images/covers/Cover-TheSockProblem.jpg'
import where from './images/covers/Cover-WhereShouldIGo.png'
import magic from './images/covers/Cover-MagicFunctions.png'
import puzzled from './images/covers/Cover-Puzzled.png'

import allAboutYsA1 from './PDFs/ys-allaboutys-a1.pdf'
import allAboutYsA2 from './PDFs/ys-allaboutys-a2.pdf'
import allAboutYsA3 from './PDFs/ys-allaboutys-a3.pdf'

import theSockProblemA1 from './PDFs/ys-thesockproblem-a1.pdf'
import theSockProblemA2 from './PDFs/ys-thesockproblem-a2.pdf'
import theSockProblemA3 from './PDFs/ys-thesockproblem-a3.pdf'
import theSockProblemA4 from './PDFs/ys-thesockproblem-a4.pdf'

import magicfunctionsA1 from './PDFs/ys-magicfunctions-a1.pdf'
import magicfunctionsA3 from './PDFs/ys-magicfunctions-a3.pdf'

import puzzledA1 from './PDFs/ys-puzzled-a1.pdf'
import puzzledA2 from './PDFs/ys-puzzled-a2.pdf'
import puzzledA3 from './PDFs/ys-puzzled-a3.pdf'

import whereA1 from './PDFs/ys-whereshouldigo-a1.pdf'
import whereA2 from './PDFs/ys-whereshouldigo-a2.pdf'

import allAboutYsPreview from "./PDFs/All About Ys - Preview.pdf"
import theSockProblemPreview from "./PDFs/The Sock Problem - Preview.pdf"
import magicFunctionsPreview from "./PDFs/Magic Functions - Preview.pdf"
import whereShouldIGoPreview from "./PDFs/Where should I Go - Preview.pdf"
import puzzledPreview from "./PDFs/Puzzled - Preview.pdf"

const Ys = ({ addToCart, user }) => {
    let ONAllStandards = [
        //ALL
        //Health and Physical Education - Social-Emotional Learning Skills
        { name: 'A1.1', grade: '1, 2, 3', area: "Health and Physical Education - Social-Emotional Learning Skills", description: "A1.1 Identification and Management of Emotions - apply skills that help them identify and manage emotions as they participate in learning experiences in health and physical  education, in order to improve their ability to express their own feelings and understand and respond to the feelings of others ", skill: 'emotions' },
        { name: 'A1.2', grade: '1, 2, 3', area: "Health and Physical Education - Social-Emotional Learning Skills", description: "A1.2 Stress Management and Coping - apply skills that help them to recognize sources of stress and to cope with challenges, including help-seeking behaviours, as they participate in learning experiences in health and physical education, in order to support the development of personal resilience", skill: 'stress' },
        { name: 'A1.3', grade: '1, 2, 3', area: "Health and Physical Education - Social-Emotional Learning Skills", description: "A1.3 Positive Motivation and Perseverance - apply skills that help them develop habits of mind that support positive motivation and perseverance as they participate in learning experiences in health and physical education, in order to promote a sense of optimism and hope", skill: 'perseverance' },
        { name: 'A1.4', grade: '1, 2, 3', area: "Health and Physical Education - Social-Emotional Learning Skills", description: "A1.4 Healthy Relationships - apply skills that help them build relationships, develop empathy, and communicate with others as they participate in learning experiences in health and physical education, in order to support healthy relationships, a sense of belonging, and respect for diversity ", skill: 'relationships' },
        { name: 'A1.5', grade: '1, 2, 3', area: "Health and Physical Education - Social-Emotional Learning Skills", description: " 1.5 Self-Awareness and Sense of Identity - apply skills that help them develop self-awareness and self-confidence as they participate in learning experiences in health and physical education, in order to support the development of a sense of identity and a sense of belonging", skill: 'identity' },
        { name: 'A1.6', grade: '1, 2, 3', area: "Health and Physical Education - Social-Emotional Learning Skills", description: "A1.6  Critical and Creative Thinking - apply skills that help them think critically and creatively as they participate in  learning experiences in health and physical education, in order to support making connections, analysing, evaluating, problem solving, and decision making", skill: 'critical thinking' },



        //Science and Technology - STEM Skills and Connections
        { name: 'A1', grade: '1, 2, 3, 4, 5', area: "Science and Technology - STEM Skills and Connections", description: "A1. STEM Investigation and Communication Skills: use a scientific research process, a scientific experimentation process, and an engineering design process to conduct investigations, following appropriate health and safety procedures", skill: 'scientific' },
        { name: 'A1.2', grade: "1, 2, 3, 4, 5", area: "Science and Technology - STEM Skills and Connections", description: "A1.2 use a scientific experimentation process and associated skills to conduct investigations", skill: 'experimentation' },
        { name: 'A1.3', grade: "1, 2, 3, 4, 5", area: "Science and Technology - STEM Skills and Connections", description: "A1.3 use an engineering design process and associated skills to design, build, and test devices, models, structures, and/or systems", skill: 'engineering' },
        { name: 'A1.4', grade: "1, 2, 3, 4, 5", area: "Science and Technology - STEM Skills and Connections", description: "A1.4 follow established health and safety procedures during science and technology investigations, including wearing appropriate protective equipment and clothing and safely using tools, instruments, and materials", skill: "safety" },
        { name: 'A1.5', grade: "1, 2, 3, 4, 5", area: "Science and Technology - STEM Skills and Connections", description: "A1.5 communicate their findings, using science and technology vocabulary and formats that are appropriate for specific audiences and purposes", skill: 'communicate' },
        { name: 'A2', grade: "1, 2, 3, 4, 5", area: "Science and Technology - STEM Skills and Connections", description: "A2. Coding and Emerging Technologies: use coding in investigations and to model concepts, and assess the impact of coding and of emerging technologies on everyday life and in STEM-related fields", skill: 'general2' },
        { name: 'A2.2', grade: '1, 2, 3', area: "Science and Technology - STEM Skills and Connections", description: 'A2.2 identify and describe impacts of coding and of emerging technologies on everyday life', skill: 'impacts' },
        { name: 'A2.2', grade: '4, 5', area: "Science and Technology - STEM Skills and Connections", description: 'A2.2 identify and describe impacts of coding and of emerging technologies on everyday life, including skilled trades', skill: 'impacts2' },
        { name: 'A3', grade: "1, 2, 3, 4, 5", area: "Science and Technology - STEM Skills and Connections", description: "A3. demonstrate an understanding of the practical applications of science and technology, and of contributions to science and technology from people with diverse lived experiences", skill: 'diversity' },
        { name: 'A3.1', grade: "1, 2, 3, 4, 5", area: "Science and Technology - STEM Skills and Connections", description: "A3.1 describe practical applications of science and technology concepts in their home and community, and how these applications address real-world problems", skill: 'real-world' },

        //Mathematics
        { name: "All", grade: "1, 2, 3, 4, 5", area: "Mathematics - Algebra", description: "C3. Coding Concepts and Skills - In Grades 1 to 3, students learn foundational concepts and practices that will allow them to successfully approach coding activities in science and technology, as well as in mathematics and other subjects. These concepts and practices include the creation of clear and precise algorithms; decomposing problems into smaller steps; and testing, debugging, and refining programs.", skill: 'all' },
        { name: 'C3', grade: "1, 2, 3, 4, 5", area: "Mathematics - Algebra", description: "C3. Coding: solve problems and create computational representations of mathematical situations using coding concepts and skills", skill: 'general1' },
        { name: 'D1.1', grade: "1", area: "Mathematics - Number", description: "D1.1 Data Collection and Organization - sort sets of data about people or things according to one attribute, and describe rules used for sorting", skill: 'data collection' },
        { name: 'B1.5', grade: "2", area: "Mathematics - Number", description: "B1.5 Whole Numbers - describe what makes a number even or odd", skill: 'oddeven' },

        //G1
        { name: 'G1-C3.1', grade: "1", area: "Mathematics - Algebra", description: "C3.1 solve problems and create computational representations of mathematical situations by writing and executing code, including code that involves sequential events", skill: 'sequence' },
        { name: 'G1-C3.2', grade: '1', area: "Mathematics - Algebra", description: 'C3.2 read and alter existing code, including code that involves sequential events, and describe how changes to the code affect the outcomes', skill: 'read and alter' },
        { name: 'G1-A1.1', grade: "1", area: "Mathematics - Algebra", description: "A1.1 use a scientific research process and associated skills to conduct investigations", skill: 'research' },
        { name: 'G1-A2.1', grade: "1", area: "Science and Technology - STEM Skills and Connections", description: "A2.1 write and execute code in investigations and when modelling concepts, with a focus on creating clear and precise instructions for simple algorithms", skill: 'algorithms' },

        //G2
        { name: 'G2-C3.1', grade: '2', area: "Mathematics - Algebra", description: 'C3.1 solve problems and create computational representations of mathematical situations by writing and executing code, including code that involves sequential and concurrent events', skill: 'concurrent events' },
        { name: 'G2-C3.2', grade: '2', area: "Mathematics - Algebra", description: 'C3.2 read and alter existing code, including code that involves sequential and concurrent events, and describe how changes to the code affect the outcomes', skill: 'read and alter concurrent events' },
        { name: 'G2-A2.1', grade: "2", area: "Science and Technology - STEM Skills and Connections", description: "A2.1 write and execute code in investigations and when modelling concepts, with a focus on decomposing problems into smaller steps", skill: 'decomposing' },

        //G3
        { name: 'G3-C3.1', grade: '3', area: "Mathematics - Algebra", description: "C3.1 solve problems and create computational representations of mathematical situations by writing and executing code, including code that involves sequential, concurrent, and repeating events", skill: 'loops' },
        { name: 'G3-C3.2', grade: '3', area: "Mathematics - Algebra", description: "C3.2 read and alter existing code, including code that involves sequential, concurrent, and repeating events, and describe how changes to the code affect the outcomes", skill: 'read and alter loops' },
        { name: 'G3-A2.1', grade: "3", area: "Science and Technology - STEM Skills and Connections", description: "A2.1 write and execute code in investigations and when modelling concepts, with a focus on testing, debugging, and refining programs", skill: 'debugging' },
        //G4
        { name: 'G4-C3.1', grade: '4', area: "Mathematics - Algebra", description: 'C3.1 solve problems and create computational representations of mathematical situations by writing and executing code, including code that involves sequential, concurrent, repeating, and nested events', skill: 'nested' },
        { name: 'G4-C3.2', grade: '4', area: "Mathematics - Algebra", description: "C3.2 read and alter existing code, including code that involves sequential, concurrent, repeating, and nested events, and describe how changes to the code affect the outcomes", skill: 'read and alter nested' },
        { name: 'G4-A2.1', grade: "4", area: "Science and Technology - STEM Skills and Connections", description: "A2.1 write and execute code in investigations and when modelling concepts, with a focus on producing different types of output for a variety of purposes", skill: 'data types' },
        //G5
        { name: 'G5-C3.1', grade: '5', area: "Mathematics - Algebra", description: 'C3.1 solve problems and create computational representations of mathematical situations by writing and executing code, including code that involves conditional statements and other control structures', skill: 'control flow' },
        { name: 'G5-C3.2', grade: '5', area: "Mathematics - Algebra", description: "C3.2 read and alter existing code, including code that involves conditional statements and other control structures, and describe how changes to the code affect the outcomes", skill: 'read and write control flow' },
        { name: 'G5-A2.1', grade: "5", area: "Science and Technology - STEM Skills and Connections", description: "A2.1 write and execute code in investigations and when modelling concepts, with a focus on using different methods to store and process data for a variety of purposes", skill: 'data storage' },
    ]

    const books = [
        {
            title: 'Ys in: All About Ys',
            image: allAboutYs,
            preview: allAboutYsPreview,
            qty: 1,
            //link: '',
            //linkKobo: 'https://www.kobo.com/ca/en/ebook/ys-in-all-about-ys',
            //linkPT: "",
            addToCart: 'YsInAllAboutYs',
            paperback: 'For paperback editions, contact us.',
            description: "Join the four main characters, Lyla (Ly), Dylon (Dy), Sylvie (Sy) and Henry (Hy), on a journey of discovery about themselves and others through coding concepts such as algorithms, booleans, data types, and inputs and outpus. Each character represents a different aspect of a child's personality and preferences developing curiosity, creativity and empathy. This book can be a valuable resource in an 'All About Me' unit, encouraging self-expression and understanding of personal identities. This interactive book promotes self-reflection, empathy, and appreciation for diversity, fostering a sense of belonging among the children as they embark on their journeys of self-discovery.",
            activities: 'As they follow the characters through the book, children will have the opportunity to complete four "All About Me" activities that encourage them to explore their own strengths and interests. They will learn about BOOLEANS, DATA TYPES, and ALGORITHMS. Through the activities and the characters, children will learn that it is okay to be different and that everyone has unique qualities that make them special. This book is perfect for parents, teachers or caregivers who want to promote self-awareness and self-esteem in children while also fostering empathy and understanding of others.',
            age: '4 to 10',
            resources: [{ title: "Activity 1", link: allAboutYsA1 }, { title: "Activity 2", link: allAboutYsA2 }, { title: "Activity 3", link: allAboutYsA3 },],
            codingSkills: ['emotions', 'identity', 'general1', 'sequence', 'read and alter', 'algorithms', 'decomposing', 'data types', 'scientific', 'general2', 'real-world', 'impacts'],
            isbn: 'ISBN 978-1-7390130-0-4, (c) GarageLab 2023, 30 pages'
        },
        {
            title: 'Ys in: Puzzled',
            image: puzzled,
            preview: puzzledPreview,
            qty: 1,
            // link: 'https://www.amazon.com/Ys-Puzzled-Learning-hands-projects-ebook/dp/B0C1T448TT/',
            //linkKobo: 'https://www.kobo.com/ca/en/ebook/ys-in-puzzled',
            //linkPT: 'https://www.amazon.com.br/gp/product/B0C36Y5NC1',
            paperback: 'For paperback editions, contact us.',
            addToCart: 'YsInPuzzled',
            description: "Join Sylvie (Sy) and Henry (Hy) as they explore the concepts of Artificial Intelligence, Machine Learning, and Deep Learning through the lens of solving puzzles. Hy and Ly find out how computers can solve puzzles by mimicking human intelligence. The book introduces the complex concepts of AI with fun and engaging activities and provides examples of how these tools can be used to solve jigsaw puzzles, Rubik's Cubes, and crossword puzzles.",
            activities: "Sy and Hy guide the readers through three fun and educational hands-on activities that allows children to see the practical applications of AI in real life. First, they will develop their own Jigsaw Puzzle using the concept of patterns in Machine Learning. Then, they play Battleship Bots where they pilot a Learning Agent robot through a maze of Reinforcement Learning. Finally, we solve a Crosswords Puzzle with the words used in the book to solidify the vocabulary through Deep Learning.",
            age: '6 to 12',
            resources: [
                { title: 'Activity 1', link: puzzledA1 },
                { title: 'Activity 2', link: puzzledA2 },
                { title: 'Activity 3', link: puzzledA3 }
            ],
            codingSkills: ['emotions', 'stress', 'perseverance', 'general1', 'read and alter', 'scientific', 'general2', 'real-world', 'impacts', 'decomposing', 'algorithms', 'impacts2'],
            isbn: 'ISBN 978-1-7390130-1-1, (c) GarageLab 2023, 36 pages'
        },
        {
            title: 'Ys in: Where Should I Go?',
            image: where,
            preview: whereShouldIGoPreview,
            qty: 1,
            //link: 'https://www.amazon.com/gp/product/B0BVGTR3L2',
            //linkKobo: 'https://www.kobo.com/ca/en/ebook/ys-in-where-should-i-go',
            //linkPT: 'https://www.amazon.com.br/gp/product/B0BXBNH44J',
            addToCart: 'YsInWhereShouldIGo',
            paperback: 'For paperback editions, contact us.',
            description: "Our story begins with Henry (Hy) feeling frustrated by not being able to choose what to do on a weekend, with many interesting options that depend on different weather conditions! With the help of Lyla (Ly) and other friends, Hy embarks on a thrilling adventure to learn more about conditionals, a powerful concept in coding and programming languages. Throughout Hy and Ly journey, children encounter different situations where they use conditionals to choose between different options, describe animals and understand the world around them. Hy and Ly interact with the readers and ask questions to help them understand the concept better.",
            activities: "Hy and Ly guide the readers through two hands-on activities to see the practical applications of conditionals in real life by using animals and shapes to decide between several options.",
            age: '4 to 10',
            resources: [{ title: "Activity 1", link: whereA1 }, { title: "Activity 2", link: whereA2 }],
            codingSkills: ['emotions', 'stress', 'perseverance', 'critical thinking', 'general1', 'sequence', 'read and alter', 'control flow', 'read and write control flow', 'scientific', 'general2', 'real-world', 'impacts', 'decomposing'],
            isbn: 'ISBN 978-1-7390130-4-2, (c) GarageLab 2023, 26 pages'

        },
        {
            title: 'Ys in: Magic Functions',
            image: magic,
            preview: magicFunctionsPreview,
            qty: 1,
            //link: 'https://www.amazon.com/gp/product/B0BZM7L18H',
            //linkKobo: 'https://www.kobo.com/ca/en/ebook/ys-in-magic-functions',
            //linkPT: "https://www.amazon.com.br/gp/product/B0BZTKX78M",
            addToCart: 'YsInMagicFunctions',
            paperback: 'For paperback editions, contact us.',
            description: "Join Sylvie (Sy) and Layla (Ly) as they learn the magic of functions. The friends discover how functions are like magic tricks, and how they can use functions to create their own tricks and games. Magic Functions introduces kids to coding, sparking their interest in being active creators of technology, not only consumers. By understanding technology, kids develop critical thinking skills. This is a book you can enjoy reading with kids and feel good about complex subjects.",
            activities: "Sy and Ly take the kids on a magical journey through three hands-on activities. First they use critical thinking to create a first function and remove any bugs (computer errors). Then, kids will follow the steps of a function to perform a fun magic trick of guessing a card using a deck of cards. Finally, they will create their own board game using functions with parameters to make their game exciting.",
            age: '6 to 12',
            resources: [{ title: "Activity 1", link: magicfunctionsA1 }, { title: "Activity 3", link: magicfunctionsA3 }],
            codingSkills: ['perseverance', 'relationships', 'general1', 'read and alter', 'loops', 'read and alter loops', 'scientific', 'general2', 'real-world', 'impacts', 'debugging',],
            isbn: 'ISBN 978-1-7390130-3-5, (c) GarageLab 2023, 36 pages'

        },
        {
            title: 'Ys in: The Sock Problem',
            image: sock,
            preview: theSockProblemPreview,
            qty: 1,
            //link: 'https://www.amazon.com/Ys-Learning-variables-hands-projects-ebook/dp/B0BVGMY6WL/',
            //linkKobo: 'https://www.kobo.com/ca/en/ebook/ys-in-the-sock-problem',
            //linkPT: "https://www.amazon.com.br/Ys-Problema-Aprendendo-CODING-projetos-ebook/dp/B0BXBL212K/",
            addToCart: 'YsInTheSockProblem',
            paperback: 'For paperback editions, contact us.',
            description: "Our story begins with Lyla (Ly) feeling frustrated by not being able to find a pair of socks, a very relatable problem for children and even adults! With the help of Dylon (Dy) and other friends, Ly lembark on a thrilling adventure to learn more about variables, a powerful concept in coding and programming languages. Throughout Ly and Dy journey, children encounter different situations where they use variables to solve problems and understand the world around them. Ly and Dy interact with the readers and ask questions to help them understand the concept better.",
            activities: 'Ly and Dy guide the readers through four activities that allow children to see the practical applications of variables in real life, developing their fine motor skills and practicing recognizing patterns, ordering, sorting, classifying and counting.',
            age: '4 to 10',
            resources: [{ title: "Activity 1", link: theSockProblemA1 }, { title: "Activity 2", link: theSockProblemA2 }, { title: "Activity 3", link: theSockProblemA3 }, { title: "Activity 4", link: theSockProblemA4 }],
            codingSkills: ['emotions', 'stress', 'perseverance', 'all', 'general1', 'data collection', 'oddeven', 'scientific', 'general2', 'real-world', 'impacts', 'data types'],
            isbn: 'ISBN 978-1-7390130-2-8, (c) GarageLab 2023, 34 pages'
        }
    ]

    let [showConnections, setShowConnections] = useState(false);
    function showHideConnections() {
        let local = showConnections;
        setShowConnections(!local);
    }

    const handleAddToCart = (event) => {
        //console.log(event.target.id)
        addToCart(event.target.id);
    }

    let [allowDownload, setAllowDonwload] = useState(false);
    // useEffect(() => {
    //     let local = false
    //     console.log(user)
    //     if (user)
    //         if (user.name)
    //             local = true;
    //     setAllowDonwload(local)

    // }, []);
    useEffect(() => {
        let local = false
        //console.log(user)
        if (user)
            if (user.name)
                local = true;
        setAllowDonwload(local)
    }, [user]);
    return (
        <div id="ys" className="Ys">
            <h2>Ys Book Series<br />
                {/* <a className="bookLink" href="https://www.amazon.com/dp/B0BVGVVKVS?binding=kindle_edition&ref=dbs_dp_rwt_sb_pc_tukn" target="_blank">Full collection at Amazon.com</a> */}
            </h2>

            {books.map(b => {
                return (

                    <div className="book" key={b.title}>

                        <div className="left">

                            <img className="bookCover" src={b.image} alt={b.title} />

                            <div className="buyLinks">
                                {b.addToCart ? <button className="buyPaperback" id={b.addToCart} onClick={handleAddToCart}> Paperback Edition<br /> CAD 14.99 - ADD TO CART</button> : <span className="tiny">Paperback Edition to be released soon</span>}
                                {b.linkKobo ? <a target="_blank" className="koboLink" href={b.linkKobo}>Buy @ <span className="koboLogo"><u>kobo</u></span></a> : null}
                                {/* {b.linkPT ? <a target="_blank" className="ptLink" href={b.linkPT}><span className="ptLogo"><u>Versão em Português</u></span></a> : null} */}
                                {b.link ? <a target="_blank" className="bookLink" href={b.link}>Buy @ <u>Amazon</u></a> : null}


                            </div>
                            {b.preview ?
                                <span className="preview">
                                    <a className="previewTag" target="_blank" href={b.preview}>OPEN PREVIEW IN NEW TAB</a>
                                </span>
                                : null}



                        </div>
                        <div className="bookDetails">
                            <h3><b>{b.title}</b> </h3>
                            <div className="isbn">{b.isbn}</div>
                            {/* <h5>Summary</h5>   */}
                            <p className="bookDescription">{b.description}</p>
                            <h5>Age</h5>         <p className="bookDescription">{b.age}</p>
                            <h5>Activities</h5>       <p className="bookDescription"> {b.activities}</p>
                            {b.resources.length > 0 ? <><p className="templates"><b>FREE ACTIVITIES TEMPLATES:</b></p>
                                {allowDownload ?
                                    b.resources.map((r) => {
                                        return (<a className="simpleLink" key={r.title + r.link} href={r.link} target="_blank" alt={r.title} rel='noopener noreferrer'>
                                            {r.title}
                                        </a>)
                                    })
                                    : <p className="templates">-- you need to be logged in to see the links --</p>
                                }</>
                                : null
                            }
                            {b.codingSkills ?
                                <>

                                    {showConnections ?
                                        <div className="currCon">
                                            <h4>Curriculum Connections</h4>
                                            <p className="tiny"><a href="https://www.dcp.edu.gov.on.ca" target='new'>ONTARIO Curriculum and Resources</a></p>
                                            {b.codingSkills.map(s => {
                                                let item = ONAllStandards.filter(item => item.skill === s)[0]
                                                return (
                                                    <p className="tiny" key={b.title + item.grade + item.name}><b>{item.area} - Grade {item.grade}</b>: {item.description}</p>
                                                )
                                            })}

                                        </div>
                                        : null}
                                    <h4> <button className="buttonConnections" onClick={showHideConnections}>{showConnections ? "Hide Curriculum Connections" : "Click here to see Curriculum Connections"}</button></h4>
                                </>
                                : null}

                        </div>
                    </div>
                )
            })}


        </div>
    )
}

export default Ys;