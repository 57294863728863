//Footer.js

const Footer = ({ user }) =>{
    return(
    <footer>
        <p  className="wrapper">&copy; Ana Rodrigues 2021 | contact@garagelab.ca</p>
       
       </footer>
    )
}
export default Footer;