//Item.js
import './Item.css';

const Item = ({ product, prices, addToCart, removeFromCart, images }) => {

    // const handleRemoveFromCart = () => {
    //     removeFromCart(product.id);
    // }

    const minusOne = () => {
        removeFromCart(product.id);
    }

    const plusOne = () => {
        addToCart(product.id);
    }

    return (
        <div className="cartProduct">


            <img className="cartImage" src={images(product.imgType, product.imgName)} alt={product.title} />
            <><span className="cartTitle"><b>{product.title}</b> <br/> CAD {prices[product.type][product.level]}</span></>
            <div className="cartQty">
                    <p><b>Qty:</b>{product.qty}</p>
                    {product.type !== "shipping" ?
                    <div className="plusminus">
                        <button onClick={plusOne}>+</button>
                        <button onClick={minusOne}>-</button>
                    </div>: null}
                </div> 
            <span className="cartPrice">CAD {((prices[product.type][product.level]) * product.qty).toFixed(2)}</span>
            {/* {product.type !== "shipping" ?
                <div className="qty">
                    <div className="cartRemoveButton">
                        <button onClick={handleRemoveFromCart}>REMOVE</button>
                    </div>

                </div>
                : null} */}

            
               
        </div>
    )
}

export default Item;