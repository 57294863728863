//Cart.js
import './Cart.css';
import Item from './Item.js';
import { createRef } from 'react';
import { PayPalButton } from "react-paypal-button-v2";

const Cart = ({ token, user, setUser, cartProducts, setCartProducts, prices, addToCart, removeFromCart, showCart, setShowCart, isPlan, images, sendEmail }) => {

    let domNodeRef = createRef();

    let total = 0;
    let productNames = [];

    function toggleShowCart() {
        setShowCart(!showCart);
    }

    // function OLDaddAllProjectsToUser(order, user, user_id, email, status, value) {

    //     if (Object.keys(cartProducts).length === 0) {
    //         //console.log('No products on cart!')
    //     } else {
    //         if (token) {
    //             console.log('Adding products!');
    //             cartProducts.forEach(i => {

    //                 //Test if it is a plan:
    //                 if (isPlan(i.id)) {
    //                     //Test if user has a plan
    //                     if (isPlan(user.subscription)) {
    //                         //if it has, ask for confirmation to o update 
    //                         let updatePlan = window.confirm(`You already have a ${user.subscription} plan. Do you want to remove the current plan and start paying a new plan?`);
    //                         if (updatePlan) {
    //                             //console.log('TO DO: Updating your plan...');
    //                         }
    //                     } else {
    //                         //Add subscription
    //                         //console.log('Adding plan');

    //                         let productId = i.id;
    //                         //Logs the transaction to user database
    //                         let activityLog = `User bought ${i.id}.`;
    //                         //Call the update in the database

    //                         let data = {
    //                             'token': token,
    //                             'planId': productId,
    //                             'activityLog': activityLog
    //                         }


    //                         fetch('/addPlanToUser', {
    //                             method: 'POST',
    //                             body: JSON.stringify(data),
    //                             headers: {
    //                                 'Content-Type': 'application/json'
    //                             }
    //                         }).then(data => data.json())
    //                             .then(data => {
    //                                 let answer = data.addedToUser;
    //                                 if (answer) {
    //                                     //console.log('Plan successfully added to user database');
    //                                     let data = {
    //                                         'token': token
    //                                     };
    //                                     fetch("/getUserByToken", {
    //                                         method: "POST",
    //                                         body: JSON.stringify(data),
    //                                         headers: { "Content-Type": "application/json" }
    //                                     })
    //                                         .then(res => {
    //                                             let response = res;
    //                                             //console.log('response: ', response)
    //                                             return response.json();
    //                                         })
    //                                         .then(response => {
    //                                             let localUser = response;
    //                                             alert('Success. Added Plan!')
    //                                             setUser(localUser);
    //                                         })
    //                                         .catch(
    //                                             e => console.log(e)
    //                                         )

    //                                 } else {
    //                                     alert('Error! Plan was not added to user database. Please contact us!');
    //                                 }


    //                             })


    //                     }


    //                 } else {
    //                     //It is a project. Add to the user projects string
    //                     let productId = i.id;
    //                     //Logs the transaction to user database

    //                     let activityLog = `User bought ${i.id}. ** PayPal: ${order} - ${user}, (${user_id}), ${email}, ${status}, ${value} ** `;
    //                     //console.log("activityLog", activityLog);
    //                     //Call the update in the database

    //                     let data = {
    //                         'token': token,
    //                         'productId': productId,
    //                         'activityLog': activityLog
    //                     }


    //                     fetch('/addAllProjectsToUser', {
    //                         method: 'POST',
    //                         body: JSON.stringify(data),
    //                         headers: {
    //                             'Content-Type': 'application/json'
    //                         }
    //                     }).then(data => data.json())
    //                         .then(data => {
    //                             let answer = data.addedToUser;
    //                             if (answer) {
    //                                 //console.log('Product ' + productId + 'successfully added to user database');
    //                                 let data = {
    //                                     'token': token
    //                                 };
    //                                 fetch("/getUserByToken", {
    //                                     method: "POST",
    //                                     body: JSON.stringify(data),
    //                                     headers: { "Content-Type": "application/json" }
    //                                 })
    //                                     .then(res => {
    //                                         let response = res;

    //                                         return response.json();
    //                                     })
    //                                     .then(response => {
    //                                         let localUser = response;
    //                                         setUser(localUser);
    //                                         setCartProducts([]);
    //                                         setShowCart(false);
    //                                         alert('Product ' + productId + ' added to your My Projects section!')


    //                                     })
    //                                     .catch(
    //                                         e => console.log(e)
    //                                     )

    //                             } else {
    //                                 alert('Error! Product was not added to user database. Please contact us!');

    //                             }


    //                         })

    //                 }
    //             })
    //         }
    //         else {
    //             alert('You must log in before buying products.');
    //         }

    //     }

    // }

    function addAllProjectsToUser(order, user, user_id, email, status, value) {
        if (Object.keys(cartProducts).length === 0) {
            //console.log('No products on cart!')
            alert('No products on cart.')
        } else {
            if (!token) {
                alert('You must log in before buying products.');
            } else {
                let productIds = '';
                let activityLogs = '';
                //console.log('Adding products!');
                cartProducts.forEach(i => {
                    productIds += i.id + ";";
                    //console.log('productsId', productIds);
                    activityLogs += `User bought ${i.id}. ** PayPal: ${order} - ${user}, (${user_id}), ${email}, ${status}, ${value} ** ;`;
                    //console.log("activityLog", activityLogs);
                });

                //Call the update in the database
                let data = {
                    'token': token,
                    'productIds': productIds,
                    'activityLog': activityLogs
                }

                fetch('/addAllProjectsToUser', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(data => data.json())
                    .then(data => {
                        let answer = data.addedToUser;
                        if (answer) {
                            //console.log('Product ' + productIds + 'successfully added to user database');
                            let data = {
                                'token': token
                            };
                            fetch("/getUserByToken", {
                                method: "POST",
                                body: JSON.stringify(data),
                                headers: { "Content-Type": "application/json" }
                            })
                                .then(res => {
                                    let response = res;

                                    return response.json();
                                })
                                .then(response => {
                                    let localUser = response;
                                    setUser(localUser);
                                    setCartProducts([]);
                                    setShowCart(false);
                                    //alert('Product ' + productIds + ' added to your My Projects section!')


                                })
                                .catch(
                                    e => console.log(e)
                                )

                        } else {
                            alert('Error! Product was not added to user database. Please contact us!');

                        }


                    })


            }
        }

    }

    const credentials = {
        "sandbox": "Ac09bd6PQGHs4c-MbwgZ-3AScvxcLteKFADbCspkvjjqj4O5Y-DU6cvdzFZ0Dzyg22imTGB9T1_CXV1l&currency=CAD",
        "production": "AbIAJf0jcFml5qjow2iJ_E3Ne9xjQBfB585MmahZvKfSGOaeTzD-UO7SKQDqjVOiLiXKBCQYPXLA2POM&currency=CAD"
    }

    return (
        <div className={showCart ? 'cartName cartVisible' : 'cartName cartHidden'}>
            <div className="shoppingCartTitle"><p>Shopping Cart</p><button className="closeShowCart" onClick={toggleShowCart}>Close</button></div>
            {
                <>
                    {Object.keys(cartProducts).length
                        ? cartProducts.map((product, index) => {
                            total += prices[product.type][product.level]*product.qty;
                            let pName = product.qty+" x "+product.title;
                            productNames.push(pName);
                            //console.log(productNames);
                            return (
                                <Item product={product} key={product.id + '-cart=' + index} prices={prices} addToCart={addToCart} removeFromCart={removeFromCart} images={images} />
                            );
                        })
                        : <p>Your cart is empty.</p>
                    }

                    {total
                        ? <p className="cartTotal">Total: CAD {total.toFixed(2)}</p>
                        : null
                    }


                    {(total > 0) ?
                        <PayPalButton
                            domNodeRef={domNodeRef}
                            

                           
                            shippingPreference="GET_FROM_FILE" // default is "GET_FROM_FILE", option "NO_SHIPPING"
                            createOrder={(data, actions) => {
                                let items = cartProducts.map((product, index) => {
                                   
                                    return {
                                        name: product.title,
                                        description: product.id,
                                        unit_amount: {
                                            currency_code: "CAD",
                                            value: prices[product.type][product.level],
                                        },
                                        quantity: product.qty,
                                    }
                                })

                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            reference_id: "GarageLab",
                                            amount: {
                                                currency_code: "CAD",
                                                value: total.toFixed(2),
                                                breakdown: {
                                                    item_total: {
                                                        currency_code: "CAD",
                                                        value: total.toFixed(2)
                                                    }
                                                }
                                            },
                                            description: 'GarageLab Shopping',
                                            items: items,
                                            
                                        }
                                    ]

                                });
                            }}
                            onSuccess={(details, data) => {
                              
                                try {
                                    const order = details.id;
                                    const user_name = details.payer.name.given_name + " " + details.payer.name.surname;
                                    const user_id = details.payer.payer_id;
                                    const email = details.payer.email_address;
                                    const status = details.status;
                                    const value = details.purchase_units[0].amount.currency_code + details.purchase_units[0].amount.value;

                                    let productN = '';

                                    productNames.forEach(p => {
                                        productN += '<li>' + p + '</li>';
                                    })

                                    //Send confirmation email:
                                    let re = user[0].email;
                                    let se = 'contact@garagelab.ca';
                                    let su = 'Thank you for your purchase!';
                                    let te = user_name + ', this email is to confirm your purchase of ' + productN + '. Total value of CAD' + value + ' at Garage Lab. If you need help, please contact us at garagelab.ca.';
                                    let ht = '<h1>Thank you, ' + user_name + '!</h1><p>This email is to confirm your purchase of:</p><ol>' + productN + '</ol> <p>Total value of ' + value + ' at Garage Lab.</p><p>If you need help, please contact us at garagelab.ca.</p>';
                                    sendEmail(re, se, su, te, ht); //user

                                    alert('Thank you for your purchase. We have sent you an email confirming your purchase.');

                                    su = 'User bought product!';
                                    te = user_name + ' purchased ' + productN + ' in the total value of CAD' + value + ' at Garage Lab.';

                                    ht = '<h1>' + user_name + '</h1><p>' + user[0].email + '</p><p>Purchase  of :</p><ol>' + productN + '</ol><p>Total value of ' + value + ' at Garage Lab.</p>';
                                    sendEmail(se, se, su, te, ht); //my control

                                    //addProductsToUser(order, user_name, user_id, email, status, value);
                                    addAllProjectsToUser(order, user_name, user_id, email, status, value);
                                } catch (e) {
                                    console.log("Error processing sale confirmation")
                                    console.log(e)

                                }






                            }}
                            onCancel={(details, data) => {
                                alert("Transaction canceled.", details, data);

                            }}
                            onError={(details, data) => {
                                //console.log('details', details);
                                //console.log('data', data);
                                alert(`Error: ${details} ${data}`);

                                //Send error email:
                                let re = user[0].email;
                                let se = 'contact@garagelab.ca';
                                let su = 'Error in transaction!';
                                let te = 'This email is to inform that there was an error processing your transaction at Garage Lab. If you need help, please contact us at garagelab.ca.';
                                let ht = '<p>This email is to inform that there was an error processing your transaction at Garage Lab.</p><p>If you need help, please contact us at garagelab.ca.</p>';
                                sendEmail(re, se, su, te, ht);
                            }}

                            options={{
                                //clientId: credentials["sandbox"]
                                clientId: credentials["production"],
                                // description: 'teste' <- doesn't work
                            }}
                        />
                        : null}


                </>

            }

        </div>
    )
}

export default Cart;