//Contact.js
import './Contact.css';
import { useState } from 'react';


const Contact = ({sendEmail}) =>{

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [message, setMessage] = useState('');

    const resetForm = () => {
        setName('');
        setEmail('');
        setReason('');
        setMessage('');        
    }

    const handleName = (e) =>{
        //console.log(e.target.value);
        setName(e.target.value);
    }

    const handleEmail = (e) =>{
        //console.log(e.target.value);
        setEmail(e.target.value);
    }

    const handleReason = (e) =>{
        //console.log(e.target.value);
        setReason(e.target.value);
    }

    const handleMessage = (e) =>{
        //console.log(e.target.value);
        setMessage(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let re = "contact@garagelab.ca";
        let se = re;
        let su = "From: "+name+" - "+reason;
        let te = `From: ${name} - Email: ${email} - ${message}`;
        let ht = `<p>From: ${name}</p><p>Subject: ${su}</p><p>Email: ${email}</p><p>${message}</p>`;

        // console.log('Recipient:', re);
        // console.log('Sender:', se);
        // console.log('Subject:', su);
        // console.log('Text:', te);
        // console.log('HTML:', ht);
        
        sendEmail(re, se, su, te, ht);
        alert('Thank you for contacting us! We will get back to you soon.');
        resetForm();

    }
    
    return (
    <div className="contactSection">
        <div className="wrapper contact">
        <h2 id="contact" >Contact</h2>
        <form onSubmit={handleSubmit}>
            <label htmlFor="name">Your name: </label><br />
            <input required type="text" value={name} onChange={handleName} placeholder="You" className="moreInfoTextBox"/><br /><br />
            <label htmlFor="email">Your email: </label><br />
            <input required type="email" value={email} onChange={handleEmail} placeholder="you@mail.com" className="moreInfoTextBox"/><br /><br />
            <label htmlFor="reason">Subject: </label><br />
            <input required type="text" value={reason} onChange={handleReason} placeholder="More information" className="moreInfoTextBox"/><br/><br/>
            <label htmlFor="email">Message: </label><br />
            <textarea required value={message} onChange={handleMessage} placeholder="Hello! I would like to know more about..." className="moreInfoTextBox"/>      
            <button className="sendEmailButton" >Send</button>
            
              </form>
              </div>
    </div>
    )
}

export default Contact;