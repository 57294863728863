//Content.js
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import React from "react";
import ImageGallery from 'react-image-gallery';
import ContentItem from './ContentItem.js';


//ALSO ADD TO THE PDFs OBJECT BELOW!
//Basic
import basicArduinoPDF from './PDFs/arduinoBasic.pdf';
import basicSolderingPDF from './PDFs/solderingBasic.pdf';
import basic3DPrintingPDF from './PDFs/basic3DPrinting.pdf';
//Intermediate
import harryPotterSortingHatPDF from './PDFs/harryPotterSortingHat_EN.pdf';
import tamagotchi1PDF from './PDFs/tamagotchi-OneColor.pdf';
import musicBoxPDF from './PDFs/musicBox.pdf';
//Advanced
import atariJoystickPDF from './PDFs/atariJoystick_EN.pdf';
import tamagotchi2PDF from './PDFs/tamagotchiMulti.pdf';


import './Content.css';

const Content = ({ token, user, allContent, addToCart, prices, isPlan, subscriptionsTypes, images, projectRef, setShowTerms }) => {
    //console.log('User in Content', user);

    const [openClose, setOpenClose] = useState(true);



    const PDFs = {
        //Basic
        'basicArduinoPDF': basicArduinoPDF,
        'basicSolderingPDF': basicSolderingPDF,
        'basic3DPrintingPDF': basic3DPrintingPDF,

        //Intermediate
        'harryPotterSortingHatPDF': harryPotterSortingHatPDF,
        'tamagotchi1PDF': tamagotchi1PDF,
        'musicBoxPDF': musicBoxPDF,

        //Advanced
        'atariJoystickPDF': atariJoystickPDF,
        'tamagotchi2PDF': tamagotchi2PDF,
    }

    const techOptions = [
        { value: 'print3D', label: '3D printing' },
        { value: 'arts', label: 'Arts and Crafts' },
        { value: 'arduino', label: 'Arduino' },
        { value: 'electronics', label: 'Electronics' },
        { value: 'makeymakey', label: 'Makey Makey' },
        { value: 'soldering', label: 'Soldering' },
    ]

    const levelOptions = [
        { value: 'sample', label: 'Sample' },
        { value: 'beginner', label: 'Beginner' },
        { value: 'intermediate', label: 'Intermediate' },
        { value: 'advanced', label: 'Advanced' }
    ]

    const [filterTech, setFilterTech] = useState(techOptions.map(i => i.value));
    const [filterLevel, setFilterLevel] = useState(levelOptions.map(i => i.value));
    const [filteredContent, setFilteredContent] = useState(allContent);

    useEffect(() => {
        let newContent = []; // all projects
        //let newContent1 = []; // projects from subscriptions
        let newContent2 = []; // projects from purchases

        // if (user) {
        if (token && user) {

            // Has a subscription:
            // if (isPlan(user.subscription)) {
            //     let today = new Date();
            //     let validUntil = new Date(user.validUntil);

            //     //console.log('Today', today);
            //     //console.log('Valid Until', validUntil);

            //     if (validUntil.getTime() >= today.getTime()) {
            //         //console.log(`${user.subscription} subscription valid until ${validUntil}!`);
            //         if (user.subscription === subscriptionsTypes[0]) {
            //             newContent1 = allContent.filter(i => i.level === "beginner" );
            //         }
            //         if (user.subscription === subscriptionsTypes[1]) {
            //             newContent1 = allContent.filter(i => i.level === "beginner" || i.level === "intermediate");
            //         }
            //         if (user.subscription === subscriptionsTypes[2] || user.subscription === subscriptionsTypes[3]) {
            //             newContent1 = allContent;
            //         }

            //     } else {
            //         //alert('You plan has expired. Please renew your plan or contact us if you think this information is not correct.');
            //     }
            // }

            // Has  projects: 
            if (user.projects) {
                // newContent2 = allContent.filter((i) => {
                //     let flag = false;
                //     //console.log('user projects', user.projects)
                //     if (user.projects) {
                //         //console.log('User projects: ', user.projects);
                //         let projects = user.projects.split(';');
                //         projects.forEach(p  => {
                //             //console.log('Project',p);
                //             if (p === i.id) {
                //                 flag = true;
                //             }
                //         });
                //     }
                //     return flag;
                // });
                //fetch allContent
                //let projects = user.projects.split(';');
                let data = { projectIds: user.projects };
                fetch("/getProjectsInfo", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: { "Content-Type": "application/json" }
                })
                    .then(res => {
                        let response = res;
                        return response.json();
                    })
                    .then(response => {
                        let localContent = response;
                        //console.log(' Local Content =', localContent);

                        newContent2 = localContent;
                        //console.log(' New Content 2 = ', newContent2);


                        //Add projects from purchases:

                        let newContentAll = newContent2;

                        //Remove duplicates:
                        newContent = [...new Set(newContentAll)];

                        //Filter for technologies and difficulty level:
                        newContent = newContent.filter((i) => {

                            //Filtering for technologies:
                            let flagTech = false;
                            i.techniques.forEach(it => {
                                if (filterTech.includes(it.name))
                                    flagTech = true;
                            })
                            if (i.optional) {
                                i.optional.forEach(it => {
                                    if (filterTech.includes(it.name))
                                        flagTech = true;
                                })
                            }

                            //Filtering for Dificulty Level:
                            let flagLevel = false;
                            if (filterLevel.includes(i.level))
                                flagLevel = true;

                            //Final result:
                            let flag = flagLevel && flagTech;

                            //Removing all subscriptions:
                            if (isPlan(i.id))
                                flag = false;

                            return flag;
                        });

                        setFilteredContent(newContent);

                    })
                    .catch(
                        e => console.log(e)
                    )

            }
            else {
                setFilteredContent(newContent);
            }
        }

    }, [user, filterTech, filterLevel, allContent, isPlan, subscriptionsTypes, token]);

    const handleFilterTech = (techFilter) => {

        //techFilter is an array with all the options selected, such as:
        //[{value: 'print3D', label: '3D printing'}, {value: 'soldering', label: 'Soldering'}]
        //newTechFilter is just the values on an Array:
        let newTechFilter = techFilter.map((t) => {
            return t.value;
        });

        setFilterTech(newTechFilter);

    }

    const handleFilterLevel = (filter) => {

        let newFilter = filter.map((t) => {
            return t.value;
        });

        setFilterLevel(newFilter);
    }

    const handleSeeTerms = () => {
        setShowTerms(true);
    }

    let filtersOn = false;

    return (
        <>{
            //user ?
            token && user ?
                <div className="allProjects" ref={projectRef}>


                    <div className="wrapper">
                        <h2 id="lessons">{user.name.split(' ')[0]}'s Projects</h2>
                        <div className="seeTerms">
                            <button onClick={handleSeeTerms}>View the Terms and Conditions</button>
                        </div>
                        {/* {user.subscription?
                        <p className="subscriptionDetails">Subscription: {user.subscription.split('Subscription')[0]} subscription valid until {user.validUntil.split(',')[2]}/{user.validUntil.split(',')[1]}/{user.validUntil.split(',')[0]}</p>
                    : null
                    }
                    {user.projects?
                    <p className="subscriptionDetails">Projects: {user.projects.split(';').toString(', ')}</p>
                    :null
                    } */}
                    </div>
                    {filtersOn ? <div>
                        <div className="wrapper">
                            <h3 className="selectTitles">Show all projects using:</h3>
                            <p className="filterHelp">(click on the x to remove a technology)</p>
                            <Select
                                className="selectOptions"
                                defaultValue={techOptions}
                                isMulti
                                options={techOptions}
                                onChange={handleFilterTech}
                            >
                            </Select>
                        </div>

                        <div className="wrapper">
                            <h3 className="selectTitles">Show all projects for:</h3>
                            <p className="filterHelp">(click on the x to remove a level)</p>
                            <Select
                                className="selectOptions"
                                defaultValue={levelOptions}
                                isMulti
                                options={levelOptions}
                                onChange={handleFilterLevel}
                            >
                            </Select>
                        </div>
                    </div>
                        : null}
                    <div className="lessons wrapper">
                        {
                            filteredContent ?
                                filteredContent.map((content, index) => {


                                    let galleryImages = content.galleryImages.map(item => {
                                        return {
                                            'original': images(item.originalType, item.originalName),
                                            'thumbnail': images(item.thumbnailType, item.thumbnailName),
                                           
                                        }
                                    });



                                    return (
                                        <div className="content-container" id={content.id} key={`lesson${index}`}>
                                            <ContentItem
                                                openClose={openClose}
                                                setOpenClose={setOpenClose}
                                                ImageGallery={ImageGallery}
                                                galleryImages={galleryImages}
                                                content={content}
                                                images={images}
                                                Tabs={Tabs}
                                                TabList={TabList}
                                                Tab={Tab}
                                                TabPanel={TabPanel}
                                                PDFs={PDFs}
                                            />







                                        </div>
                                    )

                                })

                                : null
                        }
                    </div>
                </div>
                : null}</>
    )

}

export default Content;