//Consulting.js
import './TermsAndConditions.css';

const TermsAndConditions = ({ setShowTerms }) => {

    
    return (
        <div id="terms" className="terms">
            <div className="wrapper">
                <h2>Terms and Conditions</h2>
                    
                <div className="termsInfo">
                    <h3>Product Description</h3>
                    <p>When you buy a GarageLab project you are buying:</p>
                    <ul>
                        <li>Personal use and access to the materials list for the project.</li>
                        <li>Personal use and access to the Instructions / Lesson Plan, a PDF file that includes:
                            <ol type="a">
                                <li> A Discovery section, with research ideas and questions;</li>
                                <li> A Development section, with materials, tips, and detailed instructions to build a prototype and a MVP (minimum viable project);</li>
                                <li>A Final Product section, with instructions to improve the project, as well as incentives for free exploration;</li>
                                <li>A Reflection section, with questions to help children think about what they learned during the project.</li>
                            </ol>
                        </li>
                        <li>The right to download, print one copy and use the Instructions / Lesson Plan PDF.</li>
                        <li>Support via e-mail at the discretion of GarageLab.</li>
                    </ul>
                    <p>When you buy a GarageLab project you are <strong>NOT</strong> buying:</p>
                    <ul>
                        <li>The right to reproduce or distribute the project to others, including, but not limited to, teachers, schools, students, maker spaces, other websites.</li>
                        <li>Any materials necessary to develop the project.</li>
                        <li>Unlimited support.</li>
                        <li>Any commercial use of materials from the Garage Lab site is NOT permitted without contacting Garage Lab IN ADVANCE for licensing rights. </li>
                        <li>Graphics and images from the Garage Lab projects and website, that may not be printed, copied, or downloaded separate from the documents that contain them.</li>
                    </ul>
                    <h3>Safety and liability</h3>
                    <p>The projects described in Garage Lab have been tested by Garage Lab, but Garage Lab cannot garantee that the projects are risk free. In order to minimize the risk of injury, accidents and damage, you should:</p>
                    <ul>
                        <li> Follow all the safety precautions required when using electrical equipment, sharp tools, scissors, soldering irons, chemical products and any other materials described in the projects.</li>
                        <li> Use adequate protective equipment, specially when children is present.</li>
                        <li>Never let children unsupervised when developing the projects presented here.</li>
                    </ul>

                    <h3>Project Availability</h3>
                    <ul>
                        <li>Garage Lab reserves the right to change, exclude, modify and perform maintenance in the projects in the website. If you need access to a project that is not available, contact us at garagelab.ca.</li>
                    </ul>

                    <h3>Accepting the Terms and Conditions</h3>
                    By accepting these terms and conditions you agree that:
                    <ol>
                        <li>You are an adult and you are the person responsible for buying the projects.</li>
                        <li>You will not allow children to develop any Garage Lab project unsupervised.</li>
                        <li>You understand the risks involved.</li>
                        <li>You will use adequate protective equipment, specially when children is present.</li>
                        <li> You will follow all the manufacturer's safety precautions for all materials needed in the project.</li>
                        <li> You will follow all the safety precautions required when using electrical equipment, sharp tools, scissors, soldering irons, chemical products and any other materials described in the projects.</li>
                        <li>You will follow all instructions related to safety precautions in the projects.</li>
                        <li>Garage Lab is not responsible for any accident, injury or damage to people or properties that may occur during or after the development of Garage Lab projects. It is your sole responsability to make sure that the projects are developed in a safe way.</li>
                    </ol>
                    <button className="buttonTerms" onClick={() => setShowTerms(false)} >Close</button>
                     
                     </div>
               
            </div>


        </div>



    )
}

export default TermsAndConditions;