//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import React from "react";
import ImageGallery from 'react-image-gallery';


//Content.js
import './Content.css';
import './OpenContent.css';

const OpenContent = ({ token, user, setUser, allContent, addToCart, prices, isPlan, subscriptionsTypes, images, setShowTerms, sendEmail }) => {



    //console.log('User in Open Content', user);
    function addFreeSampleToUser(id) {

        if (token && user) {
            console.log('Adding sample!', id);
            let productIds = id+";";
            console.log('Adding sample! ProductIds', productIds);
            //Logs the transaction to user database
            let activityLog = `User added sample ${productIds}.`;
            let data = {
                'token': token,
                'productIds': productIds,
                'activityLog': activityLog
            }


            fetch('/addAllProjectsToUser', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(data => data.json())
                .then(data => {
                    let answer = data.addedToUser;
                    if (answer) {
                        //console.log('Product successfully added to user database');
                        let data = {
                            'token': token
                        };
                        fetch("/getUserByToken", {
                            method: "POST",
                            body: JSON.stringify(data),
                            headers: { "Content-Type": "application/json" }
                        })
                            .then(res => {
                                let response = res;

                                return response.json();
                            })
                            .then(response => {
                                let localUser = response;
                                setUser(localUser);
                                alert('Project sample added to your My Projects section!')

                                //Send confirmation email:
                                let re = 'contact@garagelab.ca';
                                let se = 'contact@garagelab.ca';
                                let su = 'Added sample!';
                                //console.log(localUser);
                                let te = localUser[0].name +'added sample.';
                                let ht = '<h1>'+localUser[0].name +' - '+localUser[0].email+' - added sample.';
                                sendEmail(re, se, su, te, ht); 



                            })
                            .catch(
                                e => console.log(e)
                            )

                    } else {
                        alert('Error! Product was not added to user database. Please contact us!');

                    }


                })

        }
        else {
            alert('You must log in before buying products.');
        }



    }

    const techOptions = [
        { value: 'print3D', label: '3D printing' },
        { value: 'arts', label: 'Arts and Crafts' },
        { value: 'arduino', label: 'Arduino' },
        { value: 'electronics', label: 'Electronics' },
        { value: 'makeymakey', label: 'Makey Makey' },
        { value: 'soldering', label: 'Soldering' },
    ]

    const levelOptions = [
        { value: 'sample', label: 'Sample' },
        { value: 'beginner', label: 'Beginner' },
        { value: 'intermediate', label: 'Intermediate' },
        { value: 'advanced', label: 'Advanced' },

    ]

    const [filterTech, setFilterTech] = useState(techOptions.map(i => i.value));
    const [filterLevel, setFilterLevel] = useState(levelOptions.map(i => i.value));
    const [filteredContent, setFilteredContent] = useState({});




    useEffect(() => {
        function isAvailableToUser(item, user) {
            //console.log(item);
            if (user) {

                let productLevels = ["sample", "beginner", "intermediate", "advanced"];

                if (!isPlan(item.id)) {
                    //console.log(`User has ${user.subscription} and this item is ${item.level}`);
                    let userLevel = 0;
                    if (user.subscription === subscriptionsTypes[0]) userLevel = 1;
                    if (user.subscription === subscriptionsTypes[1]) userLevel = 2;
                    if (user.subscription === subscriptionsTypes[2]) userLevel = 3;
                    if (user.subscription === subscriptionsTypes[3]) userLevel = 4;
                    let itemLevel = 0;
                    if (item.level === productLevels[0]) itemLevel = 1;
                    if (item.level === productLevels[1]) itemLevel = 2;
                    if (item.level === productLevels[2]) itemLevel = 3;

                    //console.log(`User Level: ${userLevel}  Item Level: ${itemLevel}`);

                    if (userLevel >= itemLevel)
                        return false;

                    //Test if user bought that specific project

                    if (user.projects) {
                        //console.log('User projects: ', user.projects);
                        let projects = user.projects.split(';');
                        let flag = true;
                        projects.forEach(p => {
                            //console.log('Project',p, ' and Item.id', item.id);
                            if (p === item.id) {
                                flag = false;
                            }
                        });
                        return flag;
                    }

                    return true;
                }
            }
            else {
                return true;
            }
        }

        let newContent = allContent.filter(item => isAvailableToUser(item, user));

        newContent = newContent.filter((i) => {

            //Filtering for technologies:
            let flagTech = false;
            i.techniques.forEach(it => {
                if (filterTech.includes(it.name))
                    flagTech = true;
            })
            if (i.optional) {
                i.optional.forEach(it => {
                    if (filterTech.includes(it.name))
                        flagTech = true;
                })
            }

            //Filtering for Dificulty Level:
            let flagLevel = false;
            if (filterLevel.includes(i.level))
                flagLevel = true;

            //Final result:
            let flag = flagLevel && flagTech;

            //Removing all subscriptions:
            // if (isPlan(i.id))
            //     flag = false;

            return flag;
        });

        setFilteredContent(newContent);
    }, [user, filterTech, filterLevel, allContent, isPlan, subscriptionsTypes]);

    const handleFilterTech = (techFilter) => {

        //techFilter is an array with all the options selected, such as:
        //[{value: 'print3D', label: '3D printing'}, {value: 'soldering', label: 'Soldering'}]
        //newTechFilter is just the values on an Array:
        let newTechFilter = techFilter.map((t) => {
            return t.value;
        });

        setFilterTech(newTechFilter);

    }

    const handleFilterLevel = (filter) => {

        let newFilter = filter.map((t) => {
            return t.value;
        });

        setFilterLevel(newFilter);
    }

    const handleSeeTerms = () => {
        setShowTerms(true);
    }

    let filtersOn = false;

    let [whatsIncluded, setWhatsIncluded] = useState(false);
    const handleWhatsIncluded = () => {
        let state = whatsIncluded;
        whatsIncluded = setWhatsIncluded(!state);
    }
    return (
        <>
            <div className="allProjects allOpenProjects">
                <div className="wrapper">
                    <h2 id="openProjects">STEAM Projects</h2>


                    <div className="headerBottom">
                        <p>Our projects are <strong>easy to understand</strong>, <strong>interesting</strong>, <strong>appropriate for children</strong> and <strong>do not require previous knowledge</strong>!</p>
                        <p>Finally, a <strong>STEAM project</strong> that everyone will <strong>understand</strong> and <strong>enjoy</strong>!</p>
                    </div>

                    <button className="seeTerms" onClick={handleSeeTerms}>View the Terms and Conditions</button>


                    {filtersOn ? <div>
                        <h3 className="selectTitles">Show all projects using:</h3>
                        <p className="filterHelp">(click on the x to remove a technology)</p>

                        <Select
                            className="selectOptions"
                            defaultValue={techOptions}
                            isMulti
                            options={techOptions}
                            onChange={handleFilterTech}
                        >
                        </Select>
                        <h3 className="selectTitles">Show all projects for:</h3>
                        <p className="filterHelp">(click on the x to remove a level)</p>
                        <Select
                            className="selectOptions"
                            defaultValue={levelOptions}
                            isMulti
                            options={levelOptions}
                            onChange={handleFilterLevel}
                        >
                        </Select>
                    </div> : null
                    }

                </div>
                {
                    Object.keys(filteredContent).length <= 0
                        ? <p className="wrapper messageAllProjects">There are no additional projects to buy based on your purchases and filter selection.</p>
                        : <>



                            <div className="lessons wrapper">
                                {
                                    filteredContent ?
                                        filteredContent.map((content, index) => {

                                            //console.log(content.level);
                                            let itemPrice = prices[content.type][content.level];


                                            const handleAddToCart = () => {
                                                addToCart(content.id);
                                            }

                                            const handleFreeSample = () => {
                                                //alert(content.id);
                                                addFreeSampleToUser(content.id);
                                            }

                                            let galleryImages = content.galleryImages.map(item => {
                                                return {
                                                    'original': images(item.originalType, item.originalName),
                                                    'thumbnail': images(item.thumbnailType, item.thumbnailName)
                                                }
                                            });

                                            return (
                                                <div className="openProject" key={`openProjects${index}`}>
                                                    <div className="open-content-container" id={content.id} >
                                                        <div className="mainInfo">
                                                            <div className="mainImage">
                                                                <div className="myGallery">
                                                                {/* <img src={images(content.imgType, content.imgName)} className="titleImage" alt="" /> */}
                                                                    <ImageGallery items={galleryImages} showThumbnails={false}/>
                                                                </div>


                                                                {content.level === "sample" ? <button className="addToCartButton orange" onClick={handleFreeSample}>FREE SAMPLE</button> :
                                                                    <button className="addToCartButton orange" onClick={handleAddToCart}><div>CAD {itemPrice.toFixed(2)}</div>ADD TO CART</button>}



                                                                <div className="included">
                                                                
                                                                    {whatsIncluded ? 
                                                                    <div className="whatsIncluded">
                                                                        <p>MATERIALS NOT INCLUDED</p>
                                                                        <div className="closeWhatsIncluded">
                                                                        <button  onClick={handleWhatsIncluded}>x</button> </div>
                                                                        </div>
                                                                        :<div className="whatsIncluded">
                                                                            <p>MATERIALS NOT INCLUDED</p>
                                                                            <button onClick={handleWhatsIncluded}>What's Included?</button> 
                                                                        </div>
                                                                    }
                                                                    {whatsIncluded ? <div className="included">

                                                                        <p><strong>Included:</strong></p>
                                                                        <ol>
                                                                            <li>Access to the Instructions/Lesson Plan PDF developed using Creative Learning and Project-based Learning methodologies, containing:
                                                                                <ul>
                                                                                    <li>Prototyping, MVP and Final Product outline;</li>
                                                                                    <li>List of all the materials and softwares needed;</li>
                                                                                    <li>Links to all the materials needed in online stores in Canada;</li>
                                                                                    <li> ALL the necessary instructions without the need for previous knowledge; </li>
                                                                                    <li>Questions for research; </li>
                                                                                    <li>Ideas for further projects.</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li>Option to view the Instructions Lesson Plan PDF online or download and print it.</li>
                                                                            <li>Support via e-mail, up to 10 emails per project, at the discretion of GarageLab.</li>
                                                                        </ol>
                                                                        
                                                                    </div> : null}
                                                                </div>
                                                            </div>

                                                            <div className="mainText">
                                                                <h2>{content.title} <span className="level">| {content.type === 'project' ? ' ' + content.level.toUpperCase() : 'subscription plan'} </span> </h2>

                                                                <div className="secondLine">
                                                                    |{
                                                                        content.techniques.map((t, index) => {
                                                                            return (<img key={`openTech${index}`} className="techniqueImg" src={images(t.imgType, t.imgName)} alt="" />)
                                                                                ;
                                                                        })
                                                                    }

                                                                    |{

                                                                        content.optional ?
                                                                            <>
                                                                                {/* <p>Optional:</p> */}
                                                                                {content.optional.map((t, index) => {
                                                                                    return <img key={`openOptional${index}`} className="techniqueImg" src={images(t.imgType, t.imgName)} alt="" />
                                                                                })
                                                                                }|
                                                                            </>
                                                                            : null
                                                                    }


                                                                </div>
                                                                <div className="thirdLine">
                                                                    {content.description ?
                                                                        <>
                                                                            {/* <TabPanel className="content-text"> */}
                                                                            {/* <h3>Description</h3> */}
                                                                            <p className="projectDescription" > {content.description}</p>
                                                                            {/* </TabPanel> */}
                                                                        </>
                                                                        :
                                                                        null
                                                                    }

                                                                    {content.connections ?
                                                                        <div className="learningGoals">Learning Goals</div>
                                                                        : null
                                                                    }

                                                                    {content.connections ?

                                                                        content.connections.map((c, index) => {
                                                                            const itemsList = c.description;
                                                                            return (
                                                                                <div key={`openLesson${index}`} className="curriculumConnectionsText2">
                                                                                    <p> <strong>{c.name} </strong></p>
                                                                                    {
                                                                                        itemsList.map((element, index) => {
                                                                                            return <p key={`openItemLesson${index}`}>{element}</p>;
                                                                                        })
                                                                                    }
                                                                                </div>)
                                                                        })
                                                                        : null
                                                                    }

                                                                    
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            )

                                        })

                                        : null
                                }

                            </div>


                        </>
                }


            </div>
        </>
    )

}

export default OpenContent;