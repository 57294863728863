import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NewUser from './NewUser.js';
import './Login.css';



export default function Login({ token, setToken, loginData, setLoginData, setShowLogin, sendPassword, showMenu, setShowMenu, showTerms, setShowTerms, sendEmail, projectRef }) {



    function loginUser(credentials) {
        fetch('/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json())
            .then(response => {
                const localToken = response;
                //console.log('mudando o token', localToken);
                setLoginData({ 'email': email, 'password': '', 'token': localToken });
                setToken(localToken);
                setEmail('');
                setPassword('');
                setShowLogin(false);
                //setShowMenu(false);                
            })
            .catch(e => {
                alert('You are not logged in. Please try again.');
                //console.log(e);
            })
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [viewCreate, setViewCreate] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        loginUser({
            email,
            password
        });
    }


    const handleClickCreateLogin = (e) => {
        e.preventDefault();
        //window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setViewCreate(true);
    }

    const recoverPassword = (e) => {
        e.preventDefault();
        let userEmail = prompt('Type in the email you want to see the password for:');
        console.log(userEmail);
        let text = "If " + userEmail + " is on our database, the password was sent to it. Please wait up to 2 minutes, and check your inbox.";
        alert(text);
        sendPassword(userEmail);

    }

    return (
        <div className="loginAll" id="login">
            {viewCreate

                ? <div className="createNewUser">
                    <NewUser
                        setViewCreate={setViewCreate}
                        setShowTerms={setShowTerms}
                        sendEmail={sendEmail}
                    />
                </div>
                :
                <div>
                    <div className="loginTitle">
                        <h3>- Login -</h3>
                        <button className="closeLogin" onClick={() => setShowLogin(false)} >Close</button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="inputBox">
                            <label htmlFor="email">Email</label>
                            <input
                                autoCapitalize="none"
                                type="text"
                                id="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)} 
                                
                                />
                                
                        </div>
                        <div className="inputBox">
                            <label htmlFor="password">Password<br /></label>
                            <input
                                autoCapitalize="none"
                                type="password"
                                id="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)} 
                                
                                />
                        </div>
                        <div className="recoverPassword" onClick={recoverPassword}>Forgot your password?</div>
                        <button type="submit">Log in to see your projects</button>

                    </form>
                    <div className="actions">
                        <h3>- Or create new user -</h3>
                        <div className="newUserButton">
                            <button onClick={handleClickCreateLogin}>Create New User</button>
                        </div>
                    </div>
                </div>
            }


        </div>

    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}