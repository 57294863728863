import { useState } from 'react';
import { stores } from './storesList.js';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the CSS file

const ContentItem = ({ ImageGallery, galleryImages, content, images, Tabs, TabList, Tab, TabPanel, PDFs }) => {
    const [openClose, setOpenClose] = useState(false);




    return (
        <div>
            {openClose ? <div className="openCloseButton"> <button onClick={() => { setOpenClose(!openClose) }}>x</button></div>
                : <div className="openCloseButton"><button onClick={() => { setOpenClose(!openClose) }}>+</button></div>
            }


            <div className="mainInfo">
                {openClose ?
                    <div className="mainImage">
                        {/* <img src={images(content.imgType, content.imgName)} className="titleImage" alt="" /> */}
                        <div className="myContentGallery">
                            <ImageGallery items={galleryImages}  showThumbnails={false} />
                        </div>
                        {/* <button className="addToCartButton" onClick={handleAddToCart}><div>CAD {itemPrice.toFixed(2)}</div>ADD TO CART</button> */}

                    </div> : null}

                <div className="mainText">
                    <h2>{content.title} <span className="level">| {content.type === 'project' ? 'project | ' + content.level.toUpperCase() : 'subscription plan'} </span> </h2>

                    {openClose ?
                        <div className="secondLine">
                            |{
                                content.techniques.map((t, index) => {
                                    return (<img key={`tech${index}`} className="techniqueImg" src={images(t.imgType, t.imgName)} alt="" />)
                                        ;
                                })
                            }

                            |{

                                content.optional ?
                                    <>
                                        {/* <p>Optional:</p> */}
                                        {content.optional.map((t, index) => {
                                            return <img key={`optional${index}`} className="techniqueImg" src={images(t.imgType, t.imgName)} alt="" />
                                        })
                                        }|
                                    </>
                                    : null
                            }


                        </div> : null}
                    {openClose ?
                        <div className="thirdLine">
                            {content.description ?
                                <>
                                    {/* <TabPanel className="content-text"> */}
                                    {/* <h3>Description</h3> */}
                                    <p className="projectDescription" > {content.description}</p>
                                    {/* </TabPanel> */}
                                </>
                                :
                                null
                            }
                        </div> : null}
                </div>
            </div>

            {
                openClose
                    ?
                    <Tabs className="TabsPanel">
                        <TabList>
                            {/* <Tab><span className="menuTab">.</span></Tab> */}
                            {content.inspiration ? <Tab><span className="menuTab">Inspiration</span></Tab> : null}
                            {/* {content.description ? <Tab><span className="menuTab">Description</span></Tab> : null} */}
                            {content.connections ? <Tab><span className="menuTab">Learning Goals</span></Tab> : null}
                            {content.software ? <Tab><span className="menuTab">Software</span></Tab> : null}
                            {content.materials ? <Tab><span className="menuTab">Materials</span></Tab> : null}
                            {content.pdf ? <Tab><span className="menuTab">Download Instructions</span></Tab> : null}
                            {content.relatedTutorials ? <Tab><span className="menuTab">Related Tutorials</span></Tab> : null}
                            {content.materials ? <Tab><span className="menuTab">Where can I buy?</span></Tab> : null}
                        </TabList>

                        {/* <TabPanel className="content-text"></TabPanel> */}

                        {content.inspiration ?
                            <TabPanel className="content-text">

                                {/* <h3> Inspiration</h3> */}

                                <div className="imgInspiration">
                                    <img src={images(content.inspiration.imgType, content.inspiration.imgName)} alt="inspiration for this project" />
                                </div>
                                <p className="textInspiration"> {content.inspiration.text}</p>
                            </TabPanel>
                            :
                            null
                        }

                        {
                            content.connections ?
                                <TabPanel className="content-text">
                                    {/* <h3>Curriculum Connections</h3> */}
                                    {content.connections.map((c, index) => {
                                        const itemsList = c.description;
                                        return (
                                            <div key={`lesson${index}`} className="curriculumConnectionsText">
                                                <p> <strong>{c.name} </strong></p>
                                                {
                                                    itemsList.map((element, index) => {
                                                        return <p key={`lessonItem${index}`}>{element}</p>;
                                                    })
                                                }
                                            </div>)
                                    })
                                    }
                                </TabPanel>
                                :
                                null
                        }
                        {content.software ?
                            <TabPanel className="content-text">
                                {/* <h3>Software</h3> */}
                                <ul>
                                    {
                                        content.software.map((s, index) => {
                                            return (

                                                <li key={`software${index}`}><a href={s.website} target="_blank" rel="noreferrer">{s.name}</a></li>
                                            )
                                        })
                                    }
                                </ul>
                            </TabPanel>
                            : null
                        }
                        {content.materials ?
                            <TabPanel className="content-text">
                                {/* <h3>Materials</h3> */}
                                <ul className="materialsList">
                                    {
                                        content.materials.map((m, index) => {
                                            return (

                                                <li key={`material${index}`}>{(m.imgType) ? <img src={images(m.imgType, m.imgName)} className="imgMaterials" alt="" /> : null} {m.n} {m.name} </li>
                                            )
                                        })
                                    }
                                </ul>
                            </TabPanel>
                            : null
                        }
                        {
                            content.pdf ?
                                <>
                                    <TabPanel className="content-text">
                                        <p><a href={PDFs[content.pdf]}
                                            target="_blank"
                                            alt={content.title}
                                            rel='noopener noreferrer'><button>Download Instructions / Lesson Plan</button>
                                        </a>
                                        </p>
                                    </TabPanel>
                                </>
                                : null
                        }
                        {
                            content.relatedTutorials ?
                                <TabPanel className="content-text">
                                    {/* <h3>Related Tutorials</h3> */}
                                    <ul>
                                        {
                                            content.relatedTutorials.map((tut, index) => {
                                                return <li key={`tutorial${index}`}><a href={`#${tut.id}`}>{tut.name}</a></li>
                                                    ;
                                            })
                                        }
                                    </ul>

                                </TabPanel>
                                : null
                        }
                        {content.materials ?
                            <TabPanel className="content-text productStores">
                                <div>
                                <p>Click on the links for examples of products.</p>
                                <p>GarageLab <strong>does not</strong> endorse any of this products or stores, these links are examples to help you choose by yourself.</p>
                                <p>Most of the links are to bundles and bulk purchases so you can save money and have extra parts for future projects.</p>
                                <ul className="buyMaterialsList">
                                    {

                                        content.materials.map((m, index) => {
                                            let thisStore = stores[m.imgName];
                                            return (
                                                <div key={`material${index}`}>
                                                    {thisStore ?
                                                        <li>

                                                            <a target="_blank" rel="noreferrer" href={stores[m.imgName]}>
                                                                {m.name}

                                                            </a>
                                                        </li>
                                                        //:null
                                                        : <li key={`material${index}`}>{m.imgName}</li>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </ul>
                                </div>
                            </TabPanel>
                            : null
                        }
                    </Tabs>
                    : null
            }
        </div>

    )
}

export default ContentItem;