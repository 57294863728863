//Header.js
import './Header.css';
// import chain from './images/chain.png';
// import lelectronics from './images/lelectronics.png';
// import awood from './images/awood.png';
import L from './images/logo/L.png';
import A from './images/logo/A.png';
import B from './images/logo/B.png';
import instagram from './images/instagram.png';
import facebook from './images/facebook.png';
import twitter from './images/twitter.png';

const Header = () => {
    return (

        <header >


            <div className="headerAll wrapper">
                <div className="headerLeft">
                    <div className="neonSign">Garage</div>

                    <div id="animatedImages">
                        <div className="animatedImageL">
                            <img id="L" src={L} alt="L letter" />
                        </div>
                        <div className="animatedImageA">
                            <img id="A" src={A} alt="A letter" />
                        </div>
                        <div className="animatedImageB">
                            <img id="B1" src={B} alt="B letter" />
                            <img id="B2" src={B} alt="B letter" />
                        </div>
                    </div>

                </div>

                <div className="headerRight">
                    <p>Garage Lab is a <span className="emphText">community.</span></p>
                    <p>We are <span className="emphText">inventors</span>, <span className="emphText">explorers</span>, <span className="emphText">artists</span>, <span className="emphText">tinkerers</span> and <span className="emphText">visionaries</span>.</p>
                    <p>Garage Lab is our <span className="emphText">laboratory</span>, our space for <span className="emphText">collaboration</span>, our home for <span className="emphText">learning</span> and <span className="emphText">experimenting</span>.</p>
                    <h3 className="emphText">Come change the world with us!</h3>
                    <div className="socials">
                        <a target="_blank" href="https://www.instagram.com/anaorodrigues/"><img src={instagram} alt="instagram"/></a>
                        <a target="_blank" href="https://www.facebook.com/AnaOlivRodrigues"><img src={facebook} alt="facebook"/></a>
                        <a target="_blank" href="https://twitter.com/anaorodrigues"><img src={twitter} alt="twitter" /></a>
                    </div>
                </div>





            </div>

        </header>
    )

}

export default Header;