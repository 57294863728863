export const stores = {
    '2AABatteries.jpg': 'https://www.amazon.ca/Duracell-Coppertop-Battery-1-5-Volt/dp/B003SI0TD4/',
    'alFoil.png':'https://www.amazon.ca/12-x-50-Aluminum-Foil/dp/B0074JC328/',
    'arduino9Va.jpg':'https://www.amazon.ca/Battery-2-1mm-5-5mm-Arduino-Adapter/dp/B089VKR8MM/',
    'arduino9Vb.png':'https://www.amazon.ca/dp/B0014D0SL8',
    'arduinoCable.png':'https://www.amazon.ca/USB-Data-Cable-Arduino-Microcontroller/dp/B01N9IP8LF/',
    'arduinoLeonardo.png':'https://www.amazon.ca/KEYESTUDIO-Leonardo-Development-Board-Arduino/dp/B0786LJQ8K/',
    'arduinoUno.png':'https://www.amazon.ca/Elegoo-Board-ATmega328P-ATMEGA16U2-Arduino/dp/B01EWOE0UU/',
    'batteryHolder.png': 'https://www.amazon.ca/Packs-Sizes-AA-Battery-Holder/dp/B07WY3VMNN/',
    'breadboardRegular.png':'https://www.amazon.ca/SunFounder-Breadboards-Solderless-Distribution-Connecting/dp/B082KBF7MM/',
    'brownFelt.png':'https://www.amazon.ca/FabricLA-Acrylic-Felt-Yard-Wide/dp/B08WRJYP6R/',
    'buzzer.png':'https://www.amazon.ca/Gikfun-Terminals-Passive-Electronic-Arduino/dp/B01J9SEVLM/',
    'cardboardBox.png':'https://www.amazon.ca/Arcade-Buttons-Joystick-Console-12x24mm/dp/B0819ZZRKC/',
    'electricalTape.png': 'https://www.amazon.ca/3M-88-Super-3-4x44FT-Vinyl-Electrical/dp/B0002FTGEE/',
    'glue.png':'https://www.amazon.ca/Elmers-Washable-No-Run-7-625-Ounce-60310Q/dp/B00GGBI3CE/',
    'heatShrinkTubing.jpg': 'https://www.amazon.ca/Shrink-Tubing-Connectors-Waterproof-Sleeve/dp/B07MTTXJKR/',
    'jumpersMM.png': 'https://www.amazon.ca/IZOKEE-240pcs-Solderless-Breadboard-Arduino/dp/B08151TQHG/',
    'keychain.png':'https://www.amazon.ca/TWONE-Metal-Split-Keychain-Parts/dp/B01GZD5M3K/',
    'laptop.png':'https://www.apple.com/ca/macbook-air/',
    'leds.jpg': 'https://www.amazon.ca/Gikfun-Yellow-Assorted-Arduino-300pcs/dp/B01N527GKZ/',
    'led8x8Module.png':'https://www.amazon.ca/Willwin-MAX7219-Microcontroller-Compatible-Arduino/dp/B076HM5DR7/',
    'makeymakey.png':'https://makeymakey.com/',
    'maskingTape.png':'https://www.amazon.ca/Scotch%C2%AE-General-Purpose-Masking-Tape/dp/B00004Z47O/',
    'pla.jpeg':'https://www.amazon.ca/ERYONE-Filament-1-75mm-Printing-Printer/dp/B07DN4MCD7/',
    'pen.png':'https://www.amazon.ca/BIC-Assorted-MMP31-ASST-4-Color-Medium/dp/B002JFR8Q8/',
    'printer.jpg': 'https://www.amazon.ca/Flashforge-Adventure-3-3D-Printer/dp/B07JVB5LH1',
    'pushbutton1.jpg': 'https://www.amazon.ca/Gikfun-12x12x7-3-Tactile-Momentary-Arduino/dp/B01E38OS7K/',
    'resistor10K.png': 'https://www.amazon.ca/Resistor-Assortment-Resistance-Electric-Projects/dp/B07N27XYL4/',
    'scissors.png':'https://www.amazon.ca/AmazonBasics-0188-3-PACK-Multipurpose-Scissors/dp/B01BRGUAT6/',
    'solderingIron.png': 'https://www.amazon.ca/GLE2016-Soldering-Adjustable-Temperature-Different/dp/B07VCSMY5L/',
    'solderTube.png': 'https://www.amazon.ca/AUSTOR-Solder-Electrical-Soldering-Diameter/dp/B074CFBQS7/',
    'switch.png': 'https://www.amazon.ca/20pcs-Black-Switch-Electronic-Projects/dp/B00BIAVQSG/',
    'wireStripper.jpg': 'https://www.amazon.ca/ProsKit-CP-302G-Precision-Stripper-20-10/dp/B005HQR1YK/',
    '':'',
    
    
    
}