//NewUser.js
import React, { useState } from 'react';
import TermsAndConditions from '../../TermsAndConditions';
import './NewUser.css';

const NewUser = ({ setViewCreate, showTerms, setShowTerms , sendEmail}) => {
    const [, setUser] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    //const [subscription, setSubscription] = useState('');
    //const [validUntil, setValidUntil] = useState('');
    //const [projects, setProjects] = useState('');
    const today = new Date();
    const startDate = today.getFullYear() + ',' + today.getDate() + ',' + today.getMonth(); // month is 0 indexed
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [acceptTerms, setAcceptTerms] = useState('');

    //const [status, setStatus] = useState('active'); //active, deleted

    const handleSubmit = (e) => {
        e.preventDefault();

        let localUser = {
            name: name,//1
            email: email,//2
            phone: phone,//3
            password: password,//4
            subscription: '',//5
            validUntil: '',//6
            projects: '',//7
            startDate: startDate,//8
            address: address,//9
            city: city,//10
            province: province,//11
            postalCode: postalCode,//12
            country: country,//14
            acceptTerms: true, //15
            status: 'active',//13
        }


        // RESET ALL FIELDS: name, email, phone, password, address, city, province, postalCode, country, acceptTerms
        resetForm();

        //console.log(localUser);
        setUser(localUser);

        fetch("/addUser", {
            method: "POST",
            body: JSON.stringify(localUser),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .then(response => {
                //console.log("Trying to create the JSON");
                //console.log(response);
                alert(response.message);
                if (response.userCreated) {
                    //Return to login page
                    setViewCreate(false);
                    let text = "User created: "+name+" - "+email;
                    let html = "User created: "+name+" - "+email;
                    
                    sendEmail('contact@garagelab.ca','contact@garagelab.ca','new user!',text,html);
                }
            })
            .catch(e => {
                alert(e);
                //console.log(e);
            });
    }

    const resetForm = () => {
        setName('');
        setEmail('');
        setPhone('');
        setPassword('');
        setAddress('');
        setCity('');
        setProvince('');
        setPostalCode('');
        setCountry('');
        setAcceptTerms('');
    }

    const handleClickBackToLogin = (e) => {
        e.preventDefault();
        setViewCreate(false);
    }

    const handleShow = (e) => {
        e.preventDefault();
        let show = showTerms;
        setShowTerms(!show);
    }

    const handleAccept = (e) => {
        //e.preventDefault();
        //console.log('Accept terms:',e.target.checked);
        setAcceptTerms(e.target.checked);
    }

    return (
        <div className="newUser">
            <div className="loginTitle">
                <h3>New User</h3>

                <button className="closeLogin" onClick={handleClickBackToLogin}>Close</button>
            </div>

            <form onSubmit={handleSubmit} className="newUserForm">
                <div className="inputBox">
                    <label htmlFor="name">Name</label>
                    <input required type="text" id="name" value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div className="inputBox">
                    <label htmlFor="email">Email</label>
                    <input
                        autoCapitalize="none"
                        required
                        type="email"
                        id="email"
                        value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="inputBox">
                    <label htmlFor="phone">Phone</label>
                    <input required type="text" id="phone" value={phone} onChange={e => setPhone(e.target.value)} />
                </div>
                <div className="inputBox">
                    <label htmlFor="password">Password</label>
                    <input required type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
                </div>

                {/* ADD ALL FIELDS:
                k - name: name,
                k - email: email,
                k - phone: phone,
                k - password: password,
                k - address: address,
                k - city: city,
                k - province: province,
                k - postalCode: postalCode,
                k - country: country,
                k - acceptTerms: acceptTerms
                */}

                <div className="inputBox">
                    <label htmlFor="address">Address</label>
                    <input required type="text" id="address" value={address} onChange={e => setAddress(e.target.value)} />
                </div>

                <div className="inputBox">
                    <label htmlFor="city">City</label>
                    <input required type="text" id="city" value={city} onChange={e => setCity(e.target.value)} />
                </div>

                <div className="inputBox">
                    <label htmlFor="province">Province</label>
                    <input required type="text" id="province" value={province} onChange={e => setProvince(e.target.value)} />
                </div>

                <div className="inputBox">
                    <label htmlFor="postalCode">Postalcode</label>
                    <input required type="text" id="postalCode" value={postalCode} onChange={e => setPostalCode(e.target.value)} />
                </div>

                <div className="inputBox">
                    <label htmlFor="country">Country</label>
                    <input required type="text" id="country" value={country} onChange={e => setCountry(e.target.value)} />
                </div>

                <div className="inputCheckbox">
                    <input required type="checkbox" id="acceptTerms" value={acceptTerms} onChange={e => handleAccept(e)} />
                    <label htmlFor="country">I accept the <button className="viewTermsButton" onClick={e => handleShow(e)}>Terms and Conditions</button></label>
                </div>



                {acceptTerms ?
                    <button type="submit" id="createUserButton">Create User</button>
                    : <div className="messageTerms">[You need to accept the terms to include a new user.]</div>
                }

                {showTerms ? 
                    <TermsAndConditions
                    
                    setShowTerms={setShowTerms}
                    />
                    : null}


            </form>
        </div>
    )
}

export default NewUser;