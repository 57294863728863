import './App.css';
import { useState, useEffect, useRef } from 'react';

//import { prices, allContent } from './database.js';

import Nav from './Nav.js';
import Header from './Header.js';
// import Plans from './Plans.js';
// import Workshops from './Workshops.js';
import Ys from './Ys.js';
// import RestrictedArea from './RestrictedArea.js';

import Content from './Content.js';
import TermsAndConditions from './TermsAndConditions.js';
import OpenContent from './OpenContent.js';
// import Consulting from './Consulting.js';
import Contact from './Contact.js';
import Footer from './Footer.js';
import Dashboard from './components/Dashboard/Dashboard.js';
import Preferences from './components/Preferences/Preferences.js';
import Login from './components/Login/Login.js';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Cart from './Cart';
//import SendEmailTest from './SendEmailTest';

import useToken from './useToken';
//import { findAllInRenderedTree } from 'react-dom/test-utils';




function App() {



  /** MAILING */
  const sendEmail = (recipient, sender, subject, text, html) => {
    let data = {
      recipient: recipient,
      sender: sender,
      subject: subject,
      text: text,
      html: html
    };

    fetch("/sendEmail", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        return (response.text());

      })
      .then(res => {
        //let response = res;
        //console.log(response);
        //alert(response);
      })

      .catch(
        e => console.log("Error:" + e)

      )
  }

  const sendPassword = (recipient) => {
    let se = 'contact@garagelab.ca';
    let su = 'Password reminder';
    let data = {
      recipient: recipient,
      sender: se,
      subject: su,
    };

    fetch("/sendPassword", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        //console.log('Response: ', response);
        alert(response);
      })

      .catch(
        e => console.log(e)
      )
  }

  const [showTerms, setShowTerms] = useState(false);
  const [showConsulting,] = useState(false);
  const [showMenu, setShowMenu] = useState();
  const [showPlan,] = useState(false);

  const [showLogin, setShowLogin] = useState(false);

  //IMAGES:
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      return images[item.replace('./', '')] = r(item);
    });
    return images;
  }

  const allImages = {
    g: importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/)),
    m: importAll(require.context('./images/materials', false, /\.(png|jpe?g|svg)$/)),
    t: importAll(require.context('./images/techniques', false, /\.(png|jpe?g|svg)$/)),
    p: importAll(require.context('./images/projects', false, /\.(png|jpe?g|svg)$/)),
    l: importAll(require.context('./images/logo', false, /\.(png|jpe?g|svg)$/)),
    c: importAll(require.context('./images/covers', false, /\.(png|jpe?g|svg)$/)),
    gallery: importAll(require.context('./images/gallery', false, /\.(png|jpe?g|svg)$/)),
  }

  function images(type, name) {
    return allImages[type][name].default;
  }

  //Getting the content and prices from the server:
  const [prices, setPrices] = useState({});
  const [allContent, setAllContent] = useState([]);

  useEffect(() => {
    //fetch all prices:
    let data = {};
    fetch("/getAllPrices", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        return response.json();
      })
      .then(response => {
        let localPrices = response;
        //console.log('localPrices', localPrices);
        //set prices
        setPrices(localPrices);
      })
      .catch(
        e => console.log(e)
      )
  }, []);

  useEffect(() => {
    //fetch allContent
    let data = {};
    fetch("/getRestrictedContent", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;
        return response.json();
      })
      .then(response => {
        let localContent = response;
        //console.log('localContent', localContent);

        //setAllContent
        setAllContent(localContent);
      })
      .catch(
        e => console.log(e)
      )

  }, []);


  const { token, setToken } = useToken();
  useEffect(() => {
    //console.log('Token at this point: ',token);
    if (token) {
      let data = {
        'token': token
      };

      fetch("/getUserByToken", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" }
      })
        .then(res => {
          let response = res;
          //console.log('Response: ', response);
          return response.json();
        })
        .then(response => {
          let localUser = response;
          //console.log('User in response: ', localUser);
          if (localUser.length === 0) alert("Sorry, we could not find this user. Please check your email and password.")
          //console.log('User length in response: ', localUser.length);
          setUser(localUser);
        })
        .catch(
          e => console.log(e)
        )

    }
  }, [token]);


  const [cartContent, setCartContent] = useState([]);

  const [showCart, setShowCart] = useState(false);


  const subscriptionsTypes = ["CuriousSubscription", "MakerSubscription", "GuruSubscription", "MasterSubscription"];

  function isPlan(type) {
    const planTypes = subscriptionsTypes;
    let flag = false;
    for (let i = 0; i < planTypes.length; i++) {
      if (planTypes[i] === type)
        flag = true;
    }
    return flag;
  }

  const addToCart = (itemId) => {

    let newCart = [...cartContent];
    //console.log('item id = '+itemId)
    //console.log(allContent)
    const newItem = allContent.filter(item => item.id === itemId);
    //console.log('New Item = ')
    //console.log(newItem)
    if (token) {

      if (cartContent.filter(item => {
        //Test for repeated items:
        return newItem[0].id === item.id;
      }).length) {
        if (newItem[0].type === 'book') {
          let repeatItem = cartContent.find(e => e.id === itemId)
          repeatItem.qty ++;
          //Add to cart: <-- Changed to increase qty!
          //newCart.push(newItem[0]);
          setCartContent(newCart);
        }
        else {
          console.log(`This item (${newItem[0].title}) is already in your cart! `)
        }
      } else {
        //Add to cart:
        newCart.push(newItem[0]);
        setCartContent(newCart);
      }

    } else {
      alert('You must Login before adding items to your cart. If you do not have a login and password, click on Create New User.');
    }
  }

  const removeFromCart = (itemId) => {
    let newCart = [];
    let removed = false;
    for (let i = 0; i < cartContent.length; i++) {
      let item = cartContent[i]
      if (item.id === itemId && !removed) {
        if (item.qty>1){
          cartContent[i].qty--;
          removed = true;
          newCart.push(cartContent[i])
        }else{
          removed = true;
        }
       
      }
      else{
        newCart.push(cartContent[i])
      }
    }
    setCartContent(newCart);
  }

  useEffect(() => {
    if (Object.keys(cartContent).length)
      setShowCart(true);
  }, [cartContent]);

  useEffect(() => {
    let numberOfBooks = 0;
    cartContent.filter(i => i.type === "book").forEach(b => numberOfBooks+=b.qty);
    //console.log('books: '+numberOfBooks)
    let numberOfShipping = 0
    cartContent.filter(i => i.type === "shipping").forEach(s => numberOfShipping+=s.qty);
    //console.log('shipping: '+numberOfShipping)
    let hasBooks = numberOfBooks > 0;
    let hasShipping = numberOfShipping > 0;
    let newCart = [...cartContent]
    //console.log(newCart)
    newCart.sort((a,b)=>b.title.localeCompare(a.title))
    //console.log(newCart)

    //No shipping yet:
    if (hasBooks && !hasShipping){
      addToCart('shipping')
    }
    //More shipping than books:
    if (numberOfBooks < numberOfShipping && numberOfShipping>0){
      let shipping = cartContent.find(e => e.type==="shipping")
      if (shipping.qty>1) {
        shipping.qty--
        setCartContent(newCart);
      }
      else{
        shipping.qty=1
        setCartContent(newCart);
      }
    }
    //More books than shipping:
    if (numberOfBooks > numberOfShipping && numberOfBooks>0 && numberOfShipping>0){
      let shipping = cartContent.find(e => e.type==="shipping")
      shipping.qty++
      setCartContent(newCart);
    } 

    if (numberOfBooks === 0 && numberOfShipping !== 0)
      removeFromCart('shipping')
  }, [cartContent]);

  const [loginData, setLoginData] = useState();
  const [user, setUser] = useState([]);
  const projectRef = useRef(null);
  const headerRef = useRef(null);

  //Scroll to projects when user is updated <<- NOT WORKING WELL
  useEffect(() => {
    //console.log(myProjectsRef);
    // if (token && projectRef.current) {
    //   projectRef.current.scrollIntoView({ behavior: 'smooth' });
    // } else {
    //   headerRef.current.scrollIntoView({ behavior: 'smooth' });
    // }

  }, [user, token])

  //Empty cart when user is updated
  useEffect(() => {
    setCartContent([]);
  }, [user])

  //LOGIN: 
  useEffect(() => {
    if (loginData) {
      let data = {
        'token': token
      };

      fetch("/getUserByToken", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" }
      })
        .then(res => {
          let response = res;
          //console.log('Response: ', response);
          return response.json();
        })
        .then(response => {
          let localUser = response;
          //console.log('User in response: ', localUser);
          //console.log('User length in response: ', localUser.length);
          setUser(localUser);
        })
        .catch(
          e => console.log(e)
        )
    }

  }, [loginData, token]);

  return (
    <div className="App" ref={headerRef}>
      <BrowserRouter>
        <Switch>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/preferences">
            <Preferences />
          </Route>
        </Switch>
      </BrowserRouter>

      <Nav

        token={token}
        setToken={setToken}
        user={user[0]}
        setUser={setUser}
        className="navigationBar"
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showCart={showCart}
        setShowCart={setShowCart}
        isPlan={isPlan}
        showPlan={showPlan}
        setShowLogin={setShowLogin}
        showConsulting={showConsulting}
      />
      {/* <Header myPageHeader = {myPageHeader}/> */}
      {showLogin ? null : <Header />}
      {/* <SendEmailTest sendEmail={sendEmail}/> */}

      {/* <main ref={myProjectsRef}> */}
      <main>
        <Cart token={token}
          user={user}
          setUser={setUser}
          cartProducts={cartContent}
          setCartProducts={setCartContent}
          prices={prices}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
          showCart={showCart}
          setShowCart={setShowCart}
          isPlan={isPlan}
          images={images}
          sendEmail={sendEmail} />
        {showLogin ?
          <Login
            token={token}
            setToken={setToken}
            loginData={loginData}
            setLoginData={setLoginData}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            setShowLogin={setShowLogin}
            sendPassword={sendPassword}
            showTerms={showTerms}
            setShowTerms={setShowTerms}
            sendEmail={sendEmail}
            projectRef={projectRef}
          /> : null}


        {showTerms ?
          <TermsAndConditions setShowTerms={setShowTerms} />
          : null}

        <Ys addToCart={addToCart} user={user[0]}/>


        {/* <Workshops sendEmail={sendEmail} /> */}
        {/* <RestrictedArea /> */}

        {//(Object.keys(user).length)
          token && (Object.keys(user).length)
            ? <>

              {showTerms ?
                null :
                <Content
                  token={token}
                  user={user[0]}
                  allContent={allContent}
                  addToCart={addToCart}
                  prices={prices}
                  isPlan={isPlan}
                  subscriptionsTypes={subscriptionsTypes}
                  images={images}
                  projectRef={projectRef}
                  setShowTerms={setShowTerms}
                />}




            </>

            : null

        }
        {(showTerms || showLogin) ? null : < OpenContent
          token={token}
          user={user[0]}
          setUser={setUser}
          allContent={allContent}
          addToCart={addToCart}
          prices={prices}
          isPlan={isPlan}
          subscriptionsTypes={subscriptionsTypes}
          images={images}
          setShowTerms={setShowTerms}
          sendEmail={sendEmail}
        />}




        {/* {showPlan ?
          <Plans
            user={user[0]}
            allContent={allContent}
            addToCart={addToCart}
            prices={prices}
            isPlan={isPlan}
            subscriptionsTypes={subscriptionsTypes}
            images={images}
          />
          : null
        } */}


        {/* {showConsulting ?
          <Consulting
            images={images} /> : null
        } */}


        {(showTerms || showLogin) ? null :
          <Contact
            sendEmail={sendEmail}
          />
        }
      </main>
      <Footer user={user} />
    </div>
  );
}

export default App;
